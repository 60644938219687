import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import AdminFooter from './AdminFooter';
import './AdminDashboard.css';
import './WorkOrderView.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import AWS from 'aws-sdk';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const ModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const ButtonGroup = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBottom: '10px',
}));

const UploadButton = styled(Button)({
    backgroundColor: '#005b5b',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#004a4a',
    },
});

const CancelButton = styled(Button)({
    backgroundColor: '#ff4d4d',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#cc0000',
    },
});

const SaveButton = styled(Button)({
    backgroundColor: '#005b5b',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#004a4a',
    },
});

const ReadyToInvoiceButton = styled(Button)({
    backgroundColor: '#005b5b',
    color: '#ffffff',
    marginTop: '10px', // Add margin for spacing
    '&:hover': {
        backgroundColor: '#004a4a',
    },
});

const ModalButton = styled(Button)({
    backgroundColor: '#005b5b',
    color: '#ffffff',
    marginTop: '20px',
    '&:hover': {
        backgroundColor: '#004a4a',
    },
});

const WorkOrderView = () => {
    const { workOrderNo } = useParams();
    const [workOrder, setWorkOrder] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [poDetails, setPoDetails] = useState([]);
    const [workLogs, setWorkLogs] = useState([]);
    const [uploadedDocs, setUploadedDocs] = useState({
        authorityToProceed: false,
        firstAttendanceReport: false,
        secondAttendanceReport: false,
        contentsManifest: false,
        scopeOfWorks: false,
        authorityToRemoveDispose: false,
    });
    const [isAddingLog, setIsAddingLog] = useState(false);
    const [newLog, setNewLog] = useState({
        type: 'Update',
        summary: '',
        longDescription: '',
    });
    const [emailStatus, setEmailStatus] = useState({});
    const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [uploadingInvoice, setUploadingInvoice] = useState(false);
    const [selectedPo, setSelectedPo] = useState(null);
    const [isProgrammedWorkOrder, setIsProgrammedWorkOrder] = useState(false);
    const [samsPlanFile, setSamsPlanFile] = useState(null);
    const [samsPlanUrl, setSamsPlanUrl] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [actualStart, setActualStart] = useState(null); // Set to null initially
    const [actualFinish, setActualFinish] = useState(null); // Set to null initially
    const [status, setStatus] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [emailModalOpen, setEmailModalOpen] = useState(false); // New state for email modal
    const [attachments, setAttachments] = useState([]); // State to hold attachments

    // New state variables for resource allocations with example data
    const [resourceAllocations, setResourceAllocations] = useState([
        {
            attendanceIdentifier: '1st Attendance',
            owner: 'Kishan',
            resources: 'Kishan, Jagulan, +3 (Company AAA Plumbing Works)',
            startTime: '2023-10-15 08:00',
            endTime: '2023-10-15 16:00',
            status: 'Dispatched',
            scheduledBy: 'Sam',
        },
        {
            attendanceIdentifier: '2nd Attendance',
            owner: 'Jagulan',
            resources: 'Jagulan, Kishan',
            startTime: '2023-10-16 09:00',
            endTime: '2023-10-16 17:00',
            status: 'In Progress',
            scheduledBy: 'Sam',
        },
    ]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenModal = (message) => {
        setModalMessage(message);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        const fetchWorkOrder = async () => {
            const token = localStorage.getItem('token');
            try {
                let response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/details/${workOrderNo}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/details/${workOrderNo}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    setIsProgrammedWorkOrder(true);
                } else {
                    setIsProgrammedWorkOrder(false);
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch work order details');
                }

                const data = await response.json();
                setWorkOrder(data);
                setSamsPlanUrl(data.samsPlanUrl || '');

                // Set actual start and finish times if available
                setActualStart(data.actualStart ? new Date(data.actualStart) : null);
                setActualFinish(data.actualFinish ? new Date(data.actualFinish) : null);
                setStatus(data.status);
            } catch (error) {
                console.error('Error fetching work order details:', error);
            }
        };

        fetchWorkOrder();
    }, [workOrderNo]);

    useEffect(() => {
        const fetchPoDetails = async () => {
            const token = localStorage.getItem('token');
            try {
                const regularPoResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/work-order/${workOrderNo}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!regularPoResponse.ok) {
                    throw new Error('Failed to fetch regular PO details');
                }

                const regularPoData = await regularPoResponse.json();

                const programmedPoResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed-work-order/${workOrderNo}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!programmedPoResponse.ok) {
                    throw new Error('Failed to fetch programmed PO details');
                }

                const programmedPoData = await programmedPoResponse.json();

                const allPoDetails = [...regularPoData, ...programmedPoData];
                setPoDetails(allPoDetails);
            } catch (error) {
                console.error('Error fetching PO details:', error);
            }
        };

        fetchPoDetails();
    }, [workOrderNo]);

    useEffect(() => {
        const fetchResourceAllocations = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/resource-allocations/work-order/${workOrderNo}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch resource allocations');
                }

                const data = await response.json();
                setResourceAllocations(data);
            } catch (error) {
                console.error('Error fetching resource allocations:', error);
            }
        };

        fetchResourceAllocations();
    }, [workOrderNo]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleAddLog = () => {
        setIsAddingLog(true);
    };

    const handleSaveLog = () => {
        setWorkLogs([...workLogs, { ...newLog, createdAt: new Date().toLocaleString(), createdBy: 'John Doe', status: 'Submitted' }]);
        setIsAddingLog(false);
        setNewLog({ type: 'Update', summary: '', longDescription: '' });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewLog({ ...newLog, [name]: value });
    };

    const handleUpload = (docType) => {
        setUploadedDocs({ ...uploadedDocs, [docType]: true });
    };

    const handleReadyToInvoice = async () => {
        if (!actualFinish || (actualStart && actualFinish < actualStart)) {
            handleOpenModal("Cannot mark as 'Ready to Invoice'. Actual Finish time is either not set or is before Actual Start time.");
            return;
        }

        const token = localStorage.getItem('token');

        try {
            // First, save the actual start and finish times
            const saveTimesUrl = isProgrammedWorkOrder
                ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/schedule/${workOrderNo}`
                : `${process.env.REACT_APP_API_URL}/admin/work-orders/schedule/${workOrderNo}`;

            const formattedActualStart = actualStart ? moment(actualStart).format('YYYY-MM-DD HH:mm:ss') : null;
            const formattedActualFinish = actualFinish ? moment(actualFinish).format('YYYY-MM-DD HH:mm:ss') : null;

            const saveTimesResponse = await fetch(saveTimesUrl, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    actualStart: formattedActualStart,
                    actualFinish: formattedActualFinish,
                }),
            });

            if (!saveTimesResponse.ok) {
                throw new Error('Failed to save Actual Start and Actual Finish times.');
            }

            // If times are saved successfully, proceed to update the status to READY_TO_INVOICE
            const statusUrl = isProgrammedWorkOrder
                ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/status/${workOrderNo}`
                : `${process.env.REACT_APP_API_URL}/admin/work-orders/status/${workOrderNo}`;

            const statusResponse = await fetch(statusUrl, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: 'READY_TO_INVOICE' }), // Ensure the status is sent as an object
            });

            if (!statusResponse.ok) {
                throw new Error('Failed to update status to READY_TO_INVOICE');
            }

            // If both operations succeed, update the local status state
            setStatus('READY_TO_INVOICE');
            handleOpenModal("Status updated to 'READY TO INVOICE'.");
        } catch (error) {
            console.error('Error during ready to invoice process:', error);
            handleOpenModal('An error occurred while marking the work order as "Ready to Invoice". Please try again.');
        }
    };

    const handleSaveTimes = async () => {
        const token = localStorage.getItem('token');
        try {
            const url = isProgrammedWorkOrder
                ? `${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/schedule/${workOrderNo}`
                : `${process.env.REACT_APP_API_URL}/admin/work-orders/schedule/${workOrderNo}`;

            const formattedActualStart = actualStart ? moment(actualStart).format('YYYY-MM-DD HH:mm:ss') : null;
            const formattedActualFinish = actualFinish ? moment(actualFinish).format('YYYY-MM-DD HH:mm:ss') : null;

            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    actualStart: formattedActualStart,
                    actualFinish: formattedActualFinish,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update times');
            }

            handleOpenModal("Actual Start and Actual Finish times updated successfully.");
        } catch (error) {
            console.error('Error updating times:', error);
        }
    };

    const handleUploadInvoice = async (event) => {
        event.preventDefault();

        const fileInput = document.getElementById('invoiceFileInput');
        if (!fileInput.files.length) {
            handleOpenModal('Please attach an invoice file.');
            return;
        }

        const file = fileInput.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('purchaseOrderNumber', selectedPo.purchaseOrderNumber);
        formData.append('invoiceTotal', invoiceAmount);

        setUploadingInvoice(true);

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/upload-invoice`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to upload invoice');
            }

            setInvoiceModalOpen(false);
            setInvoiceAmount('');
            setSelectedPo(null);
            setTimeout(() => window.location.reload(), 1000);
        } catch (error) {
            console.error('Error uploading invoice:', error);
        } finally {
            setUploadingInvoice(false);
        }
    };

    const handleSamsPlanFileChange = (event) => {
        setSamsPlanFile(event.target.files[0]);
    };

    const uploadSamsPlanFile = async () => {
        setUploading(true);
        const token = localStorage.getItem('token');
        const folderName = `${workOrderNo}_PRG_SAMs`;

        const formData = new FormData();
        formData.append('file', samsPlanFile);
        formData.append('folder', folderName);

        try {
            setUploadMessage('Uploading SAMS Plan file...');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-sams-plan/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const s3Url = await response.text();
                await saveSamsPlanUrlToDatabase(s3Url);
                setUploading(false);
                setUploadSuccess(true);
                setUploadMessage('SAMS Plan file uploaded successfully!');

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                throw new Error('Failed to upload SAMS Plan file');
            }
        } catch (error) {
            console.error('Error uploading SAMS Plan file:', error);
            setUploading(false);
            setUploadMessage('Error uploading SAMS Plan file.');
        }
    };

    const saveSamsPlanUrlToDatabase = async (s3Url) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/save-sams-plan-url`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ workOrderNumber: workOrderNo, samsPlanUrl: s3Url }),
            });

            if (!response.ok) {
                throw new Error('Failed to save SAMS Plan URL to the database');
            }
        } catch (error) {
            console.error('Error saving SAMS Plan URL to database:', error);
            setUploadMessage('Error saving SAMS Plan URL to database.');
        }
    };

    const sendEmailReminder = async (po, subject, message) => {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const ses = new AWS.SES({ apiVersion: '2010-12-01' });

        // Define the general BCC address
        const generalBccAddress = ['programmedtradeupdates@gmail.com', 'technicalsupport@antilliaemergencynetwork.com.au'];

        // Initialize BCC and CC arrays
        let bccAddresses = [...generalBccAddress];
        let ccAddresses = [];

        // Check if the purchase order is for a programmed work order
        if (po.programmedWorkOrder && po.programmedWorkOrder.workOrderNumber) {
            const additionalBcc = ''; // Additional BCC email address (or leave it empty)

            const ccEmail = `${process.env.REACT_APP_PURCHASEORDER_PROGRAMMED_CC_EMAIL_USER}`; // CC email address

            // Only add non-empty emails to the BCC and CC arrays
            if (additionalBcc) {
                bccAddresses.push(additionalBcc);
            }
            if (ccEmail) {
                ccAddresses.push(ccEmail);
            }
        }

        const params = {
            Destination: {
                ToAddresses: [po.companyEmail],
                BccAddresses: bccAddresses.filter(Boolean), // Filter out any empty or undefined addresses
                CcAddresses: ccAddresses.length > 0 ? ccAddresses : undefined, // Only include CC if there are addresses
            },
            Message: {
                Body: {
                    Html: { Data: message },
                },
                Subject: { Data: subject },
            },
            Source: 'noreply-Reminders@antilliaemergencynetwork.com.au',
        };

        try {
            setEmailStatus((prevStatus) => ({ ...prevStatus, [po.purchaseOrderNumber]: 'sending' }));
            await ses.sendEmail(params).promise();
            setEmailStatus((prevStatus) => ({ ...prevStatus, [po.purchaseOrderNumber]: 'success' }));
            setTimeout(() => setEmailStatus((prevStatus) => ({ ...prevStatus, [po.purchaseOrderNumber]: null })), 3000);
        } catch (error) {
            console.error('Error sending email:', error);
            setEmailStatus((prevStatus) => ({ ...prevStatus, [po.purchaseOrderNumber]: 'error' }));
            setTimeout(() => setEmailStatus((prevStatus) => ({ ...prevStatus, [po.purchaseOrderNumber]: null })), 3000);
        }
    };

    const handleMakeSafeReminderClick = (po) => {
        const subject = `Make Safe Report Reminder - Antillia Emergency Network - ${po.purchaseOrderNumber}`;
        const message = `
             <p>To ${po.companyName},</p>
             <br>
        <p>This is a reminder to submit the Make Safe Report for the purchase order:
           <strong><span style="background-color: yellow;">${po.purchaseOrderNumber}</span></strong>
        </p>

        <p>via the following link:
           <a href="https://trades-programmed.antilliaemergencynetwork.com.au" target="_blank">
              https://trades-programmed.antilliaemergencynetwork.com.au
           </a>
           <p>Providing the required information through the link within 24 hours of attending the site would be greatly appreciated</p>

        </p>
        <br>
        <p>Please send relevant invoices, reports, photos, and certificates to
           <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
           and
           <a href="mailto:sgrehanmanagement@gmail.com">sgrehanmanagement@gmail.com</a>
           with reference to:
           <strong><span style="background-color: yellow;">${po.purchaseOrderNumber}</span></strong>
        </p>
        <br>

        <p style="font-size: 14px; font-family: helvetica;">
            Thank you,<br>
            Antillia Emergency Network.
        </p>

        <p style="font-size: 12px; font-family: helvetica; color: grey; margin-top: 20px;">
            This is an autogenerated email. Please do not reply directly to this email.
            Any replies should be sent to
            <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>.<br><br>
            This email message, including any attachments, is intended solely for the use of the individual or entity to whom it
             is addressed and may contain confidential and/or privileged information. If you are not the intended recipient or an
             authorised representative of the intended recipient, any review, dissemination, distribution, copying, or other use of
             this email or its attachments is strictly prohibited. If you have received this email in error, please notify the sender
              immediately and delete this message and all of its attachments. Thank you.
        </p>
            `;
        sendEmailReminder(po, subject, message);
    };

    // Function to send an Invoice/Docs Reminder email
    const handleInvoiceReminderClick = (po) => {
        const subject = `URGENT: Invoices Required - Antillia Emergency Network - ${po.purchaseOrderNumber}`;
        const message = `
            <p>To ${po.companyName},</p>
    <p>Please see below for the outstanding Purchase Order requiring the submission of invoices:</p>
    <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; width: 100%;">
        <thead>
            <tr>
                <th style="text-align: left;"><strong>Purchase Order No</strong></th>
                <th style="text-align: left;"><strong>Site Address</strong></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>${po.purchaseOrderNumber}</td>
                <td><a href="https://maps.google.com/?q=${encodeURIComponent(po.customerAddress)}" target="_blank">${po.customerAddress}</a></td>
            </tr>
        </tbody>
    </table>
    <br><br>
    <p>If invoices and completion documents can be provided to
       <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
       and
       <a href="mailto:sgrehanmanagement@gmail.com">sgrehanmanagement@gmail.com</a>
       with reference to Purchase Order No:
       <strong><span style="background-color: yellow;">${po.purchaseOrderNumber}</span></strong>
       for all correspondence as a matter of urgency, that would be greatly appreciated.
    </p>
    <br>

    <p style="font-size: 14px; font-family: helvetica;">
        Thank you,<br>
        Antillia Emergency Network
    </p>

    <p style="font-size: 12px; font-family: helvetica; color: grey; margin-top: 20px;">
        This is an autogenerated email. Please do not reply directly to this email.
        Any replies should be sent to
        <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>.<br><br>
        This email message, including any attachments, is intended solely for the use of the individual or entity to whom it is addressed
         and may contain confidential and/or privileged information. If you are not the intended recipient or an authorised representative of the intended
          recipient, any review, dissemination, distribution, copying, or other use of this email or its attachments is strictly prohibited. If you have received
          this email in error, please notify the sender immediately and delete this message and all of its attachments. Thank you.
    </p>
        `;
        sendEmailReminder(po, subject, message);
    };

    const handleSendEmailWithAttachments = async (po) => {
        setSelectedPo(po);
        setEmailModalOpen(true); // Open the modal to add attachments
    };

    const handleConfirmSendEmail = async () => {
        setEmailModalOpen(false); // Close the modal

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('toAddress', selectedPo.companyEmail);

        let emailSubject, emailBody;
        let bccAddress = 'programmedtradeupdates@gmail.com, technicalsupport@antilliaemergencynetwork.com.au';
        let ccAddress = '';

        if (isProgrammedWorkOrder) {
            ccAddress = `${process.env.REACT_APP_PURCHASEORDER_PROGRAMMED_CC_EMAIL_USER}`;
            emailSubject = `New Work Order - #${workOrderNo} - ${workOrder.address}`;
            emailBody = `
                <p>To ${selectedPo.companyName},</p>
                <p>Please see the attached Work Order for details regarding the required attendance at the specified site.</p>
                <p>The following information on the link below is to be provided within 24 hours of attending site:</p>
                <p><a href="https://trades-programmed.antilliaemergencynetwork.com.au">https://trades-programmed.antilliaemergencynetwork.com.au</a></p>
                <ul>
                    <li>Please send relevant invoices, reports, photos, and certificates to
                        <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>,
                and
                <a href="mailto:sgrehanmanagement@gmail.com">sgrehanmanagement@gmail.com</a>.
                        with reference to <strong><span style="background-color: yellow;">${selectedPo.purchaseOrderNumber}</span></strong>.
                    </li>
                    <li><span style="color: red;">Ensure all trades wear LONG & LONG onsite and attendees are PRG Inducted.</span></li>
                    <li>If induction is required, please provide Working With Children's Check, Full Name, and Email to
                        <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
                        and contact <a href="tel:+61405236236">0405 236 236</a>. Trades are not to attend site without an induction.
                    </li>
                </ul>
                <p>A reminder to send all invoices to
                <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
                using <strong><span style="background-color: yellow;">${selectedPo.purchaseOrderNumber}</span></strong>.</p>
                <p style="font-size: 14px; font-family: helvetica;">Thank you,<br>Antillia Emergency Network.</p>
                <p style="font-size: 12px; font-family: helvetica; color: grey; margin-top: 20px;">
                    This is an autogenerated email. Please do not reply directly to this email.
                    Any replies should be sent to
                    <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>.
                    This email message, including any attachments, is intended solely for the use of the individual or entity to whom it
                    is addressed and may contain confidential and/or privileged information. If you are not the intended recipient or an
                    authorised representative of the intended recipient, any review, dissemination, distribution, copying, or other use of
                    this email or its attachments is strictly prohibited. If you have received this email in error, please notify the sender
                    immediately and delete this message and all of its attachments. Thank you.
                </p>
            `;
        } else {
            emailSubject = `New Purchase Order - #${selectedPo.purchaseOrderNumber} - ${workOrderNo}`;
            emailBody = `
                <p>To ${selectedPo.companyName},</p>
                <p>Please find the attached Purchase Order.</p>
                <p>Please send all invoices and related documentation to
                <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>, with reference to <strong><span style="background-color: yellow;">${selectedPo.purchaseOrderNumber}</span></strong>.</p>
                <p style="font-size: 14px; font-family: helvetica;">Thank you,<br>Antillia Emergency Network.</p>
                <p style="font-size: 12px; font-family: helvetica; color: grey; margin-top: 20px;">
                    This is an autogenerated email. Please do not reply directly to this email.
                    Any replies should be sent to
                    <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>.
                    This email message, including any attachments, is intended solely for the use of the individual or entity to whom it
                    is addressed and may contain confidential and/or privileged information. If you are not the intended recipient or an
                    authorised representative of the intended recipient, any review, dissemination, distribution, copying, or other use of
                    this email or its attachments is strictly prohibited. If you have received this email in error, please notify the sender
                    immediately and delete this message and all of its attachments. Thank you.
                </p>
            `;
        }

        formData.append('bccAddress', bccAddress);
        if (ccAddress) {
            formData.append('ccAddress', ccAddress);
        }
        formData.append('subject', emailSubject);
        formData.append('bodyText', emailBody);
        formData.append('isProgrammedWorkOrder', isProgrammedWorkOrder);
        formData.append('workOrderNumber', workOrderNo);

        // Add attachments to the form data
        attachments.forEach((attachment) => {
            formData.append('attachments', attachment);
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${selectedPo.purchaseOrderNumber}/send-email`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to send email');
            }

            setEmailStatus((prevStatus) => ({ ...prevStatus, [selectedPo.purchaseOrderNumber]: 'success' }));
            setTimeout(() => setEmailStatus((prevStatus) => ({ ...prevStatus, [selectedPo.purchaseOrderNumber]: null })), 3000);
        } catch (error) {
            console.error('Error:', error);
            setEmailStatus((prevStatus) => ({ ...prevStatus, [selectedPo.purchaseOrderNumber]: 'error' }));
            setTimeout(() => setEmailStatus((prevStatus) => ({ ...prevStatus, [selectedPo.purchaseOrderNumber]: null })), 3000);
        }
    };

    const handleViewInvoice = async (purchaseOrderNumber) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${purchaseOrderNumber}/invoice-url`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch invoice URL');
            }

            const signedUrl = await response.text();
            window.open(signedUrl, '_blank');
        } catch (error) {
            console.error('Error fetching invoice URL:', error);
        }
    };

    const handleViewPo = async (purchaseOrderNumber) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${purchaseOrderNumber}/download-url`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch signed URL');
            }

            const signedUrl = await response.text();
            window.open(signedUrl, '_blank');
        } catch (error) {
            console.error('Error fetching signed URL:', error);
        }
    };

    const handleAttachInvoiceDetailsClick = (po) => {
        if (po.attachedInvoice) {
            handleViewInvoice(po.purchaseOrderNumber);
        } else {
            setSelectedPo(po);
            setInvoiceModalOpen(true);
        }
    };

    const handleAddAttachment = (event) => {
        const files = Array.from(event.target.files);
        setAttachments((prevAttachments) => [...prevAttachments, ...files]);
    };

    const handleRemoveAttachment = (index) => {
        setAttachments((prevAttachments) =>
            prevAttachments.filter((_, i) => i !== index)
        );
    };

    // Report names array
    const reportNames = [
        'Attendance report',
        'Room Assessment',
        'Contents Manifest',
        'Drying report',
        'Authority to proceed',
        'Authority to remove and dispose',
        'Inventory report',
    ];

    if (!workOrder) {
        return <p>Loading Work Order Details...</p>;
    }

    return (
        <>
            <AdminHeader />
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="work order detail tabs">
                    <Tab label="Summary" />
                    <Tab label="Scheduling" />
                    <Tab label="WorkLogs" />
                    <Tab label="Attachments" />
                    <Tab label="Invoicing" />
                </Tabs>

                {selectedTab === 0 && (
                    <Box p={3}>
                        <h2>Summary</h2>
                        <div className="dark-text-box-look">
                             <p>Status: <strong>{workOrder.status}</strong></p>
                            <p>Work Order Number: <strong>{workOrder.workOrderNumber}</strong></p>
                            <p>PO Received: <strong>{workOrder.dateTime}</strong></p>


                            <p>Client Name: <strong>{workOrder.contactName}</strong></p>
                            <p>Client Type: <strong>{workOrder.clientType}</strong></p>
                            <p>Client Work Order: <strong>{workOrder.jobNumber}</strong></p>
                            <p>Client Purchase Order / Service Request: <strong>{workOrder.poNumber}</strong></p>

                            <p>Job Category: <strong>{workOrder.jobCategory}</strong></p>
                            <p>Job Address: <strong>{workOrder.address}</strong></p>
                            <p>Account: <strong>{workOrder.account}</strong></p>
                            <p>Details of Incident: <strong>{workOrder.detailsOfIncident}</strong></p>

                            <p>Site Contact Name: <strong>{workOrder.contactName}</strong></p>
                            <p>Site Contact Number: <strong>{workOrder.contactPhone}</strong></p>
                            <p>Site Contact Mobile: <strong>{workOrder.contactMobile}</strong></p>
                            <p>Site Contact Email: <strong>{workOrder.siteContactEmail}</strong></p>

                            <p>Target Start: <strong>{workOrder.targetStart}</strong></p>
                            <p>Target Finish: <strong>{workOrder.targetFinish}</strong></p>
                            <br></br>
                            <p>Actual Start: <strong>{workOrder.actualStart}</strong></p>
                            <p>Actual Finish: <strong>{workOrder.actualFinish}</strong></p>




                            <br></br>
                            <p>Created At: <strong>{new Date(workOrder.createdAt).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</strong></p>
                            <p>Created By: <strong>{workOrder.createdBy}</strong></p>

                        </div>
                        <br />
                        <hr />
                        <br />
                        <h2>PO Details</h2>
                        <Link to={`/admin/purchase-orders/add-new-purchase-order?workOrderNo=${workOrderNo}`}>
                            <Button variant="contained" color="primary" className="create-po-button">
                                Create New Purchase Order
                            </Button>
                        </Link>
                        <div className="po-details-grid">
                            {poDetails.map((po, index) => (
                                <div key={index} className={`po-card ${po.paymentStatus === 'PAID' ? 'paid' : ''}`}>
                                    <div className="po-details-and-buttons">
                                        <div className="po-details-container">
                                            <p>PO Number: <strong>{po.purchaseOrderNumber}</strong></p>
                                            <p>Supplier Name: <strong>{po.companyName}</strong></p>
                                            <p>Issue Date: <strong>{po.issueDate}</strong></p>
                                            <p>Target Finish: <strong>{po.finishDate}</strong></p>
                                            <p>Invoice Total: <strong>{po.finalInvoiceTotal ? `$${po.finalInvoiceTotal}` : 'N/A'}</strong></p>
                                            <Button
                                                variant="contained"
                                                className="view-po-button"
                                                onClick={() => handleViewPo(po.purchaseOrderNumber)}
                                            >
                                                View PO
                                            </Button>
                                        </div>
                                        <div className="vertical-divider"></div>
                                        <div className="button-container">
                                            <Button
                                                variant="contained"
                                                className="po-button"
                                                onClick={() => handleMakeSafeReminderClick(po)}
                                            >
                                                Make Safe Report Reminder
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="po-button"
                                                onClick={() => handleInvoiceReminderClick(po)}
                                            >
                                                Invoice / Docs Reminder
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="po-button"
                                                onClick={() => handleAttachInvoiceDetailsClick(po)}
                                            >
                                                {po.attachedInvoice ? 'View Invoice' : 'Attach Invoice Details'}
                                            </Button>
                                            {/* Add the "Send PO Email" Button */}
                                            <Button
                                            variant="contained"
                                            className="po-button"
                                            onClick={() => handleSendEmailWithAttachments(po)}
                                            style={{
                                                marginTop: '32px',  // Add some space between the buttons
                                                fontSize: '12px',  // Smaller text size
                                                padding: '4px 8px',  // Adjust padding to make the button smaller
                                                minWidth: 'auto',  // Make sure the button width fits the content
                                                height: 'auto'  // Adjust height to fit the content
                                            }}
                                        >
                                            RESEND PO Email
                                        </Button>
                                        </div>
                                    </div>
                                    {emailStatus[po.purchaseOrderNumber] && (
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                            {emailStatus[po.purchaseOrderNumber] === 'sending' && <CircularProgress />}
                                            {emailStatus[po.purchaseOrderNumber] === 'success' && (
                                                <Box display="flex" alignItems="center">
                                                    <DoneIcon style={{ color: 'green' }} />
                                                    <Typography variant="body1" style={{ color: 'green', marginLeft: '10px' }}>
                                                        Email sent successfully!
                                                    </Typography>
                                                </Box>
                                            )}
                                            {emailStatus[po.purchaseOrderNumber] === 'error' && (
                                                <Box display="flex" alignItems="center">
                                                    <ErrorIcon style={{ color: 'red' }} />
                                                    <Typography variant="body1" style={{ color: 'red', marginLeft: '10px' }}>
                                                        Failed to send email. Please try again.
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </div>
                            ))}
                        </div>
                        <br />
                        <hr />
                        <br />
                        <h2>Long Description</h2>
                        <div className="long-description-container">
                            {workOrder.description || workOrder.notes}
                        </div>
                        <br />
                        <hr />
                        <br />
                        {isProgrammedWorkOrder && (
                            <>
                                <h2>SAMs Plan</h2>
                                <Box sx={{ display: 'flex', gap: '10px' }}>
                                    {samsPlanUrl ? (
                                        <Button
                                            className="sams-button"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#005b5b',
                                                color: '#ffffff',
                                                '&:hover': {
                                                    backgroundColor: '#004a4a',
                                                },
                                            }}
                                            onClick={() => window.open(samsPlanUrl, '_blank')}
                                        >
                                            View SAMs Plan
                                        </Button>
                                    ) : (
                                        <Typography>No SAMs Plan uploaded yet.</Typography>
                                    )}
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#005b5b',
                                            color: '#ffffff',
                                            '&:hover': {
                                                backgroundColor: '#004a4a',
                                            },
                                        }}
                                        onClick={handleOpen}
                                    >
                                        Update / Attach SAMs Plan
                                    </Button>
                                </Box>

                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="upload-sams-plan-modal"
                                    aria-describedby="upload-sams-plan-modal-description"
                                >
                                    <ModalBox>
                                        <Typography id="upload-sams-plan-modal" variant="h6" sx={{ marginBottom: '16px' }}>
                                            Please Attach the SAMs Plan (*PDF Only!)
                                        </Typography>
                                        <input
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                            onChange={handleSamsPlanFileChange}
                                            style={{ marginBottom: '16px' }}
                                        />
                                        {uploading ? (
                                            <CircularProgress />
                                        ) : uploadSuccess ? (
                                            <DoneIcon color="success" fontSize="large" />
                                        ) : (
                                            <ButtonGroup>
                                                <UploadButton
                                                    variant="contained"
                                                    onClick={uploadSamsPlanFile}
                                                >
                                                    Upload
                                                </UploadButton>
                                                <CancelButton
                                                    variant="contained"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </CancelButton>
                                            </ButtonGroup>
                                        )}
                                        {uploadMessage && <Typography>{uploadMessage}</Typography>}
                                    </ModalBox>
                                </Modal>
                            </>
                        )}
                    </Box>
                )}
                {selectedTab === 1 && (
                    <Box p={3}>
                        <h2>Scheduling</h2>
                        <div className="dark-text-box-look">
                            <p>Status: <strong>{status}</strong></p>
                            <p>Target Start: <strong>{workOrder.targetStart}</strong></p>
                            <p>Target Finish: <strong>{workOrder.targetFinish}</strong></p>
                            <div>
                                <label>Actual Start: </label>
                                <DatePicker
                                    selected={actualStart}
                                    onChange={(date) => setActualStart(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    className="date-picker"
                                    placeholderText="DD-MM-YYYY HH:mm"
                                />
                            </div>
                            <div>
                                <label>Actual Finish: </label>
                                <DatePicker
                                    selected={actualFinish}
                                    onChange={(date) => setActualFinish(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    className="date-picker"
                                    placeholderText="DD-MM-YYYY HH:mm"
                                />
                            </div>
                            <Box display="flex" flexDirection="column" gap={2} mt={3} width="auto">
                                <SaveButton sx={{ width: 'auto', alignSelf: 'flex-start' }} onClick={handleSaveTimes}>
                                    Save Times
                                </SaveButton>
                                <ReadyToInvoiceButton sx={{ width: 'auto', alignSelf: 'flex-start' }} onClick={handleReadyToInvoice}>
                                    Ready to Invoice
                                </ReadyToInvoiceButton>
                            </Box>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <h2>Resource Allocation</h2>
                        <Link to={`/admin/resource-allocations/add-new?workOrderNo=${workOrderNo}`}>
                            <Button variant="contained" color="primary" className="create-po-button">
                                Create New Resource Allocation
                            </Button>
                        </Link>
                        <div className="po-details-grid">
                            {resourceAllocations.map((allocation, index) => (
                                <div key={index} className="po-card">
                                    <div className="po-details-and-buttons">
                                        <div className="po-details-container">
                                            <p>Attendance Identifier: <strong>{allocation.attendanceIdentifier}</strong></p>
                                            <p>Owner: <strong>{allocation.owner}</strong></p>
                                            <p>Resources: <strong>{allocation.resources}</strong></p>
                                            <p>Start Time: <strong>{allocation.startTime}</strong></p>
                                            <p>End Time: <strong>{allocation.endTime}</strong></p>
                                            <p>Status: <strong>{allocation.status}</strong></p>
                                            <p>Scheduled By: <strong>{allocation.scheduledBy}</strong></p>
                                            </div>
                                        <div className="vertical-divider"></div>
                                        <div className="button-container">
                                            <div className="button-row-resource">
                                                <Button
                                                    variant="contained"
                                                    className="view-po-button"
                                                    component={Link}
                                                    to={`/admin/reports/${allocation.attendanceIdentifier}`}
                                                >
                                                    View Reports
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className="view-po-button"
                                                    component={Link}
                                                    to={`/admin/reports/${allocation.attendanceIdentifier}`}
                                                >
                                                    Generate Reports
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Box>
                )}
                                {selectedTab === 2 && (
                    <Box p={3}>
                        <h2>WorkLog</h2>
                        <button className="add-button" onClick={handleAddLog}>Add Work Log</button>
                        {isAddingLog && (
                            <div className="add-work-log">
                                <h3>Add Work Order Notes</h3>
                                <label>
                                    Type:
                                    <select name="type" value={newLog.type} onChange={handleChange}>
                                        <option value="Update">Update</option>
                                        <option value="Work">Work</option>
                                        <option value="Satisfaction Rating">Satisfaction Rating</option>
                                    </select>
                                </label>
                                <label>
                                    Summary:
                                    <input type="text" name="summary" value={newLog.summary} onChange={handleChange} />
                                </label>
                                <label>
                                    Long Description:
                                    <textarea name="longDescription" value={newLog.longDescription} onChange={handleChange}></textarea>
                                </label>
                                <button className="save-button" onClick={handleSaveLog}>OK</button>
                                <button className="cancel-button" onClick={() => setIsAddingLog(false)}>Cancel</button>
                            </div>
                        )}
                        <table className="work-log-table">
                            <thead>
                                <tr>
                                    <th>Edit</th>
                                    <th>Type</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                    <th>Description</th>
                                    <th>Long Description</th>
                                    <th>Status</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {workLogs.map((log, index) => (
                                    <tr key={index}>
                                        <td>Edit</td>
                                        <td>{log.type}</td>
                                        <td>{log.createdBy}</td>
                                        <td>{log.createdAt}</td>
                                        <td>{log.summary}</td>
                                        <td>{log.longDescription}</td>
                                        <td>{log.status}</td>
                                        <td>Delete</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Box>
                )}
                {selectedTab === 3 && (
                    <Box p={3}>
                        <h2>Reports</h2>
                        {resourceAllocations.map((attendance, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>
                                        {attendance.attendanceIdentifier} - Status: {attendance.status}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Report</TableCell>
                                                <TableCell>View Report</TableCell>
                                                <TableCell>Generate Report</TableCell>
                                                <TableCell>Download Report</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportNames.map((reportName, idx) => (
                                                <TableRow key={idx}>
                                                    <TableCell>{reportName}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            style={{ backgroundColor: '#005b5b', color: '#ffffff' }}
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant="contained">Generate</Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant="contained">Download</Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                )}
            </Box>

            <Modal open={invoiceModalOpen} onClose={() => setInvoiceModalOpen(false)}>
                <div className="modal-box">
                    <Typography variant="h6" gutterBottom>
                        Attach Invoice Details
                    </Typography>
                    <form onSubmit={handleUploadInvoice}>
                        <div className="form-field">
                            <label htmlFor="invoiceAmount" className="form-label">Invoice Amount (incl GST):</label>
                            <div className="dollar-input-wrapper">
                                <span className="dollar-sign">$</span>
                                <input
                                    type="text"
                                    id="invoiceAmount"
                                    name="invoiceAmount"
                                    value={invoiceAmount}
                                    className="dollar-input"
                                    onChange={(e) => setInvoiceAmount(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-field">
                            <label htmlFor="invoiceFileInput" className="form-label">Attach Invoice:</label>
                            <input
                                type="file"
                                id="invoiceFileInput"
                                name="file"
                                accept="application/pdf, image/*"
                                className="file-input"
                                required
                            />
                        </div>
                        <div className="button-group">
                            <Button type="submit" disabled={uploadingInvoice} className="upload-button">
                                {uploadingInvoice ? <CircularProgress size={24} /> : 'Upload'}
                            </Button>
                            <Button onClick={() => setInvoiceModalOpen(false)} className="cancel-button">
                                Cancel
                            </Button>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal open={openModal} onClose={handleCloseModal}>
                <ModalBox>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                    <ModalButton onClick={handleCloseModal}>OK</ModalButton>
                </ModalBox>
            </Modal>

            {/* Email Modal for adding attachments */}
            <Modal open={emailModalOpen} onClose={() => setEmailModalOpen(false)} className="modal-overlay">
    <div className="modal-content">
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
            Attach files to include in the email:
        </Typography>
        <input type="file" multiple onChange={handleAddAttachment} />

        {/* Display added attachments */}
        <div className="attachments-list">
            {attachments.map((attachment, index) => (
                <div key={index} className="attachment-item">
                    {attachment.name}
                    <div
                        className="remove-attachment"
                        onClick={() => handleRemoveAttachment(index)}
                    >
                        &times;
                    </div>
                </div>
            ))}
        </div>

        <div className="modal-actions">
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#4caf50',
          color: 'white',
          '&:hover': {
            backgroundColor: '#66bb6a', // Lighter green on hover
          },
          borderRadius: '4px',
          padding: '10px 20px',
          fontSize: '16px',
          transition: 'background-color 0.3s ease',
        }}
        onClick={handleConfirmSendEmail}
      >
        Send Email
      </Button>
      <Button
        variant="outlined"
        sx={{
          color: '#f44336',
          borderColor: '#f44336',
          '&:hover': {
            backgroundColor: '#f8d7da', // Light red on hover
            color: '#721c24', // Dark red text on hover
          },
          borderRadius: '4px',
          padding: '10px 20px',
          fontSize: '16px',
          transition: 'background-color 0.3s ease, color 0.3s ease',
        }}
        onClick={() => setEmailModalOpen(false)}
      >
        Cancel
      </Button>
    </div>
    </div>
</Modal>



            <AdminFooter />
        </>
    );
};

export default WorkOrderView;
