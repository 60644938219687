import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  format,
  compareAsc,
  isValid,
  parseISO,
} from 'date-fns';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../AdminDashboard.css';
import './ResourceAllocation.css';

const inputHeight = '55px'; // Adjust this to change the height of all input fields

const StyledFormControl = styled(FormControl)`
  margin-top: 16px;
  margin-bottom: 8px;
  .MuiInputBase-root {
    height: ${inputHeight}; // Use the inputHeight variable here
  }
`;

const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

const LabourContainer = styled.div`
  margin-top: 20px;
`;

const LabourFields = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
`;

const Heading = styled.h2`
  color: black;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#000000', // Hardcoded black color
    fontWeight: 'bold',
    textAlign: 'left',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
  },
}));

function ResourceAllocation() {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [workOrder, setWorkOrder] = useState('');
  const [resources, setResources] = useState([]);
  const [scopeOfWorks, setScopeOfWorks] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [finishTime, setFinishTime] = useState(null);
  const [scheduledBy, setScheduledBy] = useState('');
  const [owner, setOwner] = useState('');
  const [workOrders, setWorkOrders] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [labourEntries, setLabourEntries] = useState([
    { additionalLabour: '', labourCompany: '' },
  ]);
  const [attendanceIdentifier] = useState('1st Attendance');

  const workOrderNumbers = [
    'AEN02001_Melbourne Gardens Primary School_TCMS - Programmed',
    'AEN02002-Bridgewater_Elementary-Horizon Middle School_TCMS-DHS',
    'AEN02003_Lakeside High-Crystal Lake College-Patterson Build Group',
    'AEN02004_Springfield Academy-Elmwood School_TCMS-CHL',
  ];

  const ownerAndResourceOptions = ['Kishan', 'Jagulan'];

  const technicianRefs = useRef(
    ownerAndResourceOptions.reduce((acc, name) => {
      acc[name] = React.createRef();
      return acc;
    }, {})
  );

  // Retrieve the username from localStorage
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setScheduledBy(storedUsername);
    }
  }, []);

  useEffect(() => {
    const savedWorkOrders = localStorage.getItem('workOrders');
    if (savedWorkOrders) {
      setWorkOrders(JSON.parse(savedWorkOrders));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('workOrders', JSON.stringify(workOrders));
  }, [workOrders]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation code
    if (!workOrder) {
      window.alert('Please fill in the Work Order No');
      return;
    }
    if (resources.length === 0) {
      window.alert('Please select at least one Resource');
      return;
    }
    if (!scopeOfWorks) {
      window.alert('Please fill in the Scope of Works');
      return;
    }
    if (!startTime) {
      window.alert('Please select the Start Time');
      return;
    }
    if (!finishTime) {
      window.alert('Please select the Finish Time');
      return;
    }
    if (!scheduledBy) {
      window.alert('Please select Scheduled By');
      return;
    }
    if (!owner) {
      window.alert('Please select the Owner');
      return;
    }

    const newSchedule = {
      scheduledBy,
      resources: [...resources, owner], // Include owner in resources
      scopeOfWorks,
      owner,
      startTime: startTime.toISOString(),
      finishTime: finishTime.toISOString(),
      status: 'Dispatched',
      labourEntries: labourEntries.filter(
        (entry) => entry.additionalLabour && entry.labourCompany
      ),
    };

    const updatedWorkOrders = [...workOrders];
    const existingWorkOrderIndex = updatedWorkOrders.findIndex(
      (order) => order.workOrder === workOrder
    );

    if (existingWorkOrderIndex > -1) {
      updatedWorkOrders[existingWorkOrderIndex].schedules.push(newSchedule);
    } else {
      updatedWorkOrders.push({
        workOrder,
        schedules: [newSchedule],
      });
    }

    setWorkOrders(updatedWorkOrders);
    localStorage.setItem('workOrders', JSON.stringify(updatedWorkOrders));

    // Reset form fields
    setWorkOrder('');
    setResources([]);
    setScopeOfWorks('');
    setStartTime(null);
    setFinishTime(null);
    setOwner('');
    setLabourEntries([{ additionalLabour: '', labourCompany: '' }]);
  };

  const addLabourEntry = () => {
    setLabourEntries([...labourEntries, { additionalLabour: '', labourCompany: '' }]);
  };

  const removeLabourEntry = (index) => {
    setLabourEntries(labourEntries.filter((_, i) => i !== index));
  };

  const handleLabourChange = (index, field, value) => {
    const updatedLabourEntries = [...labourEntries];
    updatedLabourEntries[index][field] = value;
    setLabourEntries(updatedLabourEntries);
  };

  const filteredResources = ownerAndResourceOptions.filter((option) => option !== owner);

  const handleWorkOrderClick = (workOrder) => {
    setSelectedWorkOrder(workOrder);
  };

  const scrollToTechnician = (name) => {
    if (technicianRefs.current[name]) {
      technicianRefs.current[name].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Updated parseWorkOrder function
  const parseWorkOrder = (workOrderString) => {
    const parts = workOrderString.split(/[_-]/);
    return {
      workOrderNo: parts[0],
      address: '', // Empty for now
      account: '', // Empty for now
    };
  };

  // Process all schedules
  const allSchedules = workOrders.flatMap((order) => {
    const { workOrderNo, account, address } = parseWorkOrder(order.workOrder);
    return order.schedules.map((schedule) => ({
      ...schedule,
      workOrderNo,
      account,
      address,
    }));
  });

  // Filter schedules by selected date and group by resource
  const schedulesByResource = {};

  allSchedules.forEach((schedule) => {
    const scheduleDate = parseISO(schedule.startTime);
    const isScheduleDateValid = isValid(scheduleDate);
    const isDateMatched = selectedDate
      ? isScheduleDateValid && scheduleDate.toDateString() === selectedDate.toDateString()
      : true;

    if (isDateMatched) {
      schedule.resources.forEach((resource) => {
        if (!schedulesByResource[resource]) {
          schedulesByResource[resource] = [];
        }
        schedulesByResource[resource].push(schedule);
      });
    }
  });

  // Function to format resources and labour entries
  function formatResources(schedule) {
    const resources = schedule.resources || [];
    const labourEntries = schedule.labourEntries || [];

    const labourStrings = labourEntries
      .map((entry) => {
        if (entry.additionalLabour && entry.labourCompany) {
          return `+${entry.additionalLabour}(${entry.labourCompany})`;
        }
        return null;
      })
      .filter(Boolean);

    return [...resources, ...labourStrings].join(', ');
  }

  return (
    <div>
      <AdminHeader />
      <Box sx={{ width: '100%' }}>
        <Paper elevation={3}>
          <Tabs value={value} onChange={handleChange} aria-label="resource allocation tabs">
            <Tab label="+ Schedule Resource" />
            <Tab label="Scheduled Jobs" />
            <Tab label="Resources" />
          </Tabs>

          {value === 0 && (
            <Box
              component="form"
              className="resource-allocation-form"
              sx={{ padding: 2 }}
              onSubmit={handleSubmit}
            >
              <Heading>Resource Allocation</Heading>

              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="work-order-label">Work Order No</InputLabel>
                <Select
                  labelId="work-order-label"
                  value={workOrder}
                  onChange={(e) => setWorkOrder(e.target.value)}
                  label="Work Order No"
                >
                  {workOrderNumbers.map((order) => (
                    <MenuItem key={order} value={order}>
                      {order}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Attendance Identifier Field */}
              <TextField
                fullWidth
                margin="normal"
                value={attendanceIdentifier}
                label="Attendance Identifier"
                InputProps={{
                  readOnly: true,
                  style: {
                    backgroundColor: '#f0f0f0',
                    color: '#555',
                  },
                }}
                variant="outlined"
              />

              {/* Owner Dropdown */}
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="owner-label">Owner</InputLabel>
                <Select
                  labelId="owner-label"
                  value={owner}
                  onChange={(e) => setOwner(e.target.value)}
                  label="Owner"
                >
                  {ownerAndResourceOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Resources Dropdown */}
              <StyledFormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="resources-label">Resources</InputLabel>
                <Select
                  labelId="resources-label"
                  multiple
                  value={resources}
                  onChange={(e) => setResources(e.target.value)}
                  label="Resources"
                >
                  {filteredResources.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Additional Labour Section */}
              <LabourContainer>
                {labourEntries.map((entry, index) => (
                  <LabourFields key={index}>
                    <StyledFormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel id={`labour-label-${index}`}>Additional Labour</InputLabel>
                      <Select
                        labelId={`labour-label-${index}`}
                        value={entry.additionalLabour}
                        onChange={(e) =>
                          handleLabourChange(index, 'additionalLabour', e.target.value)
                        }
                        label="Additional Labour"
                      >
                        {Array.from({ length: 11 }, (_, i) => (
                          <MenuItem key={i} value={i}>
                            +{i}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>

                    <TextField
                      fullWidth
                      margin="normal"
                      value={entry.labourCompany}
                      onChange={(e) =>
                        handleLabourChange(index, 'labourCompany', e.target.value)
                      }
                      label="Labour Company"
                      variant="outlined"
                    />

                    {index > 0 && (
                      <Button
                        className="delete-button"
                        onClick={() => removeLabourEntry(index)}
                        variant="contained"
                      >
                        Delete
                      </Button>
                    )}
                  </LabourFields>
                ))}

                <Button className="add-button" onClick={addLabourEntry} variant="contained">
                  +Labour Company
                </Button>
              </LabourContainer>

              <TextField
                fullWidth
                multiline
                rows={10}
                margin="normal"
                value={scopeOfWorks}
                onChange={(e) => setScopeOfWorks(e.target.value)}
                label="Scope Of Work"
                variant="outlined"
              />

              {/* Start and Finish Time one below the other */}
              <TimeContainer>
                <StyledFormControl fullWidth margin="normal" variant="outlined">
                  <DatePicker
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    dateFormat="dd-MM-yyyy HH:mm"
                    placeholderText="Select Start Time"
                    customInput={<TextField label="Start Time" variant="outlined" />}
                    popperPlacement="bottom-start"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '0, 8',
                      },
                    }}
                    className="custom-datepicker"
                  />
                </StyledFormControl>

                <StyledFormControl fullWidth margin="normal" variant="outlined">
                  <DatePicker
                    selected={finishTime}
                    onChange={(date) => setFinishTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    dateFormat="dd-MM-yyyy HH:mm"
                    placeholderText="Select Finish Time"
                    customInput={<TextField label="Finish Time" variant="outlined" />}
                    popperPlacement="bottom-start"
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '0, 8',
                      },
                    }}
                    className="custom-datepicker"
                  />
                </StyledFormControl>
              </TimeContainer>

              {/* Scheduled By (Read Only Field) */}
              <TextField
                fullWidth
                margin="normal"
                value={scheduledBy}
                label="Scheduled By"
                InputProps={{
                  readOnly: true,
                  style: {
                    backgroundColor: '#f0f0f0',
                    color: '#555',
                  },
                }}
                variant="outlined"
              />

              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button type="submit" variant="contained" className="MuiButton-root">
                  Schedule Job
                </Button>
              </div>
            </Box>
          )}

          {/* Work Orders Section */}
          {value === 1 && (
            <Box sx={{ padding: 2 }}>
              <h2>Scheduled Jobs</h2>
              {selectedWorkOrder ? (
                <Box>
                  <Button
                    className="back-to-list"
                    onClick={() => setSelectedWorkOrder(null)}
                    sx={{ marginBottom: 2 }}
                  >
                    Back to List
                  </Button>
                  <h3>Work Order No: {selectedWorkOrder.workOrder}</h3>
                  {selectedWorkOrder.schedules
                    .sort((a, b) => compareAsc(parseISO(a.startTime), parseISO(b.startTime)))
                    .map((schedule, index) => (
                      <Box key={index}>
                        <h4>Schedule {index + 1}</h4>
                        <Paper className="schedule-card">
                          <Box className="schedule-details">
                            <p>
                              <strong>Scheduled By:</strong> {schedule.scheduledBy}
                            </p>
                            <p>
                              <strong>Resources Selected:</strong> {formatResources(schedule)}
                            </p>
                            <p>
                              <strong>Scope of Work:</strong> {schedule.scopeOfWorks}
                            </p>
                            <p>
                              <strong>Start Time:</strong>{' '}
                              {isValid(parseISO(schedule.startTime))
                                ? format(parseISO(schedule.startTime), 'dd-MM-yyyy - HH:mm')
                                : 'Invalid date'}
                            </p>
                            <p>
                              <strong>Finish Time:</strong>{' '}
                              {isValid(parseISO(schedule.finishTime))
                                ? format(parseISO(schedule.finishTime), 'dd-MM-yyyy - HH:mm')
                                : 'Invalid date'}
                            </p>
                          </Box>
                        </Paper>
                      </Box>
                    ))}
                </Box>
              ) : (
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="scheduled jobs table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Work Order No</StyledTableCell>
                        <StyledTableCell>Scheduled By</StyledTableCell>
                        <StyledTableCell>Resources Selected</StyledTableCell>
                        <StyledTableCell>Start Time</StyledTableCell>
                        <StyledTableCell>Finish Time</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workOrders.map((order, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Button variant="text" onClick={() => handleWorkOrderClick(order)}>
                              {order.workOrder}
                            </Button>
                          </TableCell>
                          <TableCell>
                            {order.schedules && order.schedules[0]?.scheduledBy}
                          </TableCell>
                          <TableCell>
                            {order.schedules && formatResources(order.schedules[0])}
                          </TableCell>
                          <TableCell>
                            {order.schedules && order.schedules[0]?.startTime
                              ? format(
                                  parseISO(order.schedules[0].startTime),
                                  'dd-MM-yyyy - HH:mm'
                                )
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {order.schedules && order.schedules[0]?.finishTime
                              ? format(
                                  parseISO(order.schedules[0].finishTime),
                                  'dd-MM-yyyy - HH:mm'
                                )
                              : 'N/A'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}

          {/* Resources Section */}
          {value === 2 && (
            <Box sx={{ padding: 2 }}>
              <h2>Resources</h2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 2,
                }}
              >
                <Box>
                  {selectedDate && (
                    <Typography variant="h6">
                      {format(selectedDate, 'do MMMM yyyy')}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MMMM d, yyyy"
                    customInput={
                      <Button variant="contained" sx={{ marginBottom: 2 }}>
                        Select Date
                      </Button>
                    }
                    popperPlacement="right-start" // Move calendar to the right
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '0, 10',
                      },
                    }}
                  />
                  {selectedDate && (
                    <Button variant="text" onClick={() => setSelectedDate(null)}>
                      Clear Date
                    </Button>
                  )}
                </Box>
              </Box>
              {/* Render tables for each resource */}
              {ownerAndResourceOptions.map((resourceName) => (
                <div key={resourceName} ref={technicianRefs.current[resourceName]}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 4, textAlign: 'center', fontWeight: 'bold' }}
                  >
                    {resourceName}
                  </Typography>
                  <TableContainer component={Paper}>
  <Table size="small" aria-label="resource schedule table">
    <TableHead>
      <TableRow>
        <StyledTableCell style={{ maxWidth: '60px', padding: '4px' }}>Date</StyledTableCell>
        <StyledTableCell style={{ maxWidth: '80px', padding: '4px' }}>Time Slot</StyledTableCell>
        <StyledTableCell style={{ maxWidth: '100px', padding: '4px' }}>Account</StyledTableCell>
        <StyledTableCell style={{ maxWidth: '150px', padding: '4px' }}>Address</StyledTableCell>
        <StyledTableCell style={{ maxWidth: '400px', padding: '4px' }}>Scope of Works</StyledTableCell>
        <StyledTableCell style={{ maxWidth: '80px', padding: '4px' }}>Status</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {(schedulesByResource[resourceName] || []).map((schedule, index) => {
        const startDate = parseISO(schedule.startTime);
        const finishDate = parseISO(schedule.finishTime);

        const isStartValid = isValid(startDate);
        const isFinishValid = isValid(finishDate);

        return (
          <TableRow key={index}>
            <TableCell style={{ maxWidth: '60px', padding: '4px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {isStartValid ? format(startDate, 'dd-MM-yyyy') : 'Invalid date'}
            </TableCell>
            <TableCell style={{ maxWidth: '80px', padding: '4px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {isStartValid && isFinishValid
                ? `${format(startDate, 'HH:mm')} - ${format(finishDate, 'HH:mm')}`
                : 'Invalid time'}
            </TableCell>
            <TableCell style={{ maxWidth: '100px', padding: '4px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {schedule.account}
            </TableCell>
            <TableCell style={{ maxWidth: '150px', padding: '4px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {schedule.address}
            </TableCell>
            <TableCell style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', maxWidth: '400px', padding: '4px' }}>
              {schedule.scopeOfWorks}
            </TableCell>
            <TableCell style={{ maxWidth: '80px', padding: '4px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {schedule.status}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>

                </div>
              ))}
            </Box>
          )}
        </Paper>
      </Box>
      <AdminFooter />
    </div>
  );
}

export default ResourceAllocation;
