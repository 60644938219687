import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import AdminHeader from '../AdminHeader';
import AdminFooter from '../AdminFooter';
import './AddPurchaseOrder.css';
import moment from 'moment';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import { ClipLoader } from 'react-spinners';

// Set the workerSrc to the appropriate path
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const companies = [
  {
    name: 'SAM TEST',
    phone: '0412636686',
    email: 'shathumass@hotmail.com',
    abn: '42753507287'
  },
  {
    name: 'TEST COMPANY 1',
    phone: '0412636686',
    email: 'technicalsupport@antilliaemergencynetwork.com.au',
    abn: '42753507287'
  },
  {
    name: 'TEST COMPANY 2 ',
    phone: '0412636686',
    email: 'salmaanismath456@gmail.com',
    abn: '42753507287'
  },
  {
    name: 'Seamus Grehan',
    phone: '0412636686',
    email: 'SGrehanManagement@gmail.com',
    abn: '42753507287'
  },
  {
    name: 'Clean to Rest (Private) Limited',
    phone: '1300 371 117',
    email: 'cleantorest@gmail.com',
    abn: '41644278400'
  },
  {
    name: 'WARNA FACILITY SERVICES PTY LTD',
    phone: '+61 411 216 817',
    email: 'yohanwarnafs@gmail.com',
    abn: '59632837220'
  },
  {
    name: 'LJMciver Carpentry',
    phone: '+61 435 588 776',
    email: 'ljmcivercarpentry@gmail.com',
    abn: '94784937843'
  },
  {
    name: 'All Site Temporary Fencing Pty Ltd',
    phone: 'N/A',
    email: 'allsitetemporaryfencing@gmail.com',
    abn: '14617040392'
  },
  {
    name: 'Between The Pipes PTY LTD',
    phone: '(+61) 4 31040565',
    email: 'admin@betweenthepipes.com.au',
    abn: '78751805385'
  },
  {
    name: 'A&H Plumbing & Maintenance PTY LTD',
    phone: '0468 358 115',
    email: 'ahplumbing@outlook.com',
    abn: '99163489049'
  },
  {
    name: 'Hunter Plumbing & Maintenance PTY LTD',
    phone: '0456 999 690',
    email: 'hunterplumbingandmaintenance@gmail.com',
    abn: '62324463850'
  },
  {
    name: 'AAA Glass (Klepway Nominees P/L)',
    phone: '03 9338 1281',
    email: 'enquiries@aaaglass.com.au',
    abn: '26005437838'
  },
  {
    name: 'Rebelect Electrical',
    phone: '0439 999 004',
    email: 'adam@rebelect.com.au',
    abn: '35613231477'
  },
  {
    name: 'YZ Painting & Plastering',
    phone: '0469 358051',
    email: 'yzpaintingplastering@hotmail.com',
    abn: '55794172813'
  }
];

const AddNewPurchaseOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const initialWorkOrderNumber = query.get('workOrderNo') || '';

  const [workOrderNumbers, setWorkOrderNumbers] = useState([]);
  const [programmedWorkOrderNumbers, setProgrammedWorkOrderNumbers] = useState([]);
  const [issueDate, setIssueDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(moment().add(2, 'days').toDate());
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerContact, setCustomerContact] = useState('');
  const [supplierDetails, setSupplierDetails] = useState({
    companyName: '',
    companyPhone: '',
    companyEmail: '',
    companyABN: ''
  });
  const [selectedCompany, setSelectedCompany] = useState('');
  const [orderPlacedBy, setOrderPlacedBy] = useState({
    name: '',
    position: 'ADMINISTRATION',
    contactNumber: '0405 236 236',
    email: 'admin@antilliaemergencynetwork.com.au'
  });

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      let contactNumber = '0405 236 236'; // default phone number

      // Set the phone number based on the username
      if (storedUsername === 'Seamus Grehan') {
        contactNumber = '(041) 263 6686';
      }

      setOrderPlacedBy(prevState => ({
        ...prevState,
        contactNumber: contactNumber
      }));
    }
  }, []);



  const [scopeOfWorks, setScopeOfWorks] = useState({
    makeSafe: '',
    notes: ''
  });
  const [items, setItems] = useState([
    { code: '1101 - Labour', resources: 1, days: 1, hours: 0, qty: 1, unitPrice: 0, extPrice: 0, gst: 0, total: 0 },
    { code: '1102 - Materials / Chemicals', description: '', qty: 1, unitPrice: 0, extPrice: 0, gst: 0, total: 0 },
    { code: '1103 - Equipment', description: '', qty: 1, unitPrice: 0, extPrice: 0, gst: 0, total: 0 },
    { code: '1104 - Disposal', description: '', qty: 1, unitPrice: 0, extPrice: 0, gst: 0, total: 0 },
  ]);

  const [workOrderNumber, setWorkOrderNumber] = useState(initialWorkOrderNumber);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isProgrammedWorkOrder, setIsProgrammedWorkOrder] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [statusMessages, setStatusMessages] = useState([]);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const pdfContainerRef = useRef(null);
  const workOrderRef = useRef(null);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setOrderPlacedBy(prevState => ({
        ...prevState,
        name: storedUsername
      }));
    }

    const fetchWorkOrderNumbers = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/numbers`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch work order numbers');
        }
        const data = await response.json();
        setWorkOrderNumbers(data);
      } catch (error) {
        console.error('Error fetching work order numbers:', error);
      }
    };

    const fetchProgrammedWorkOrderNumbers = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/numbers`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch programmed work order numbers');
        }
        const data = await response.json();
        setProgrammedWorkOrderNumbers(data);
      } catch (error) {
        console.error('Error fetching programmed work order numbers:', error);
      }
    };

    fetchWorkOrderNumbers();
    fetchProgrammedWorkOrderNumbers();
  }, []);

  useEffect(() => {
    if (initialWorkOrderNumber) {
      handleWorkOrderNumberChange({ value: initialWorkOrderNumber });
    }
  }, [initialWorkOrderNumber]);

  useEffect(() => {
    if (pdfUrl) {
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      loadingTask.promise.then(pdf => {
        const pagesPromises = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          pagesPromises.push(pdf.getPage(i).then(page => {
            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };

            return page.render(renderContext).promise.then(() => {
              const pageContainer = document.createElement('div');
              pageContainer.style.marginBottom = '30px'; // Adds gap between pages
              pageContainer.appendChild(canvas);
              return pageContainer;
            });
          }));
        }

        Promise.all(pagesPromises).then(pageContainers => {
          if (pdfContainerRef.current) {
            pdfContainerRef.current.innerHTML = '';
            pageContainers.forEach(container => pdfContainerRef.current.appendChild(container));
          }
          setIsGeneratingPDF(false);
        });
      }, function (reason) {
        console.error(reason);
        setIsGeneratingPDF(false);
      });
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (statusMessages.length > 3) {
      setTimeout(() => {
        setStatusMessages(prevMessages => prevMessages.slice(1));
      }, 4000);
    }
  }, [statusMessages]);

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;

    if (field === 'unitPrice' || field === 'qty') {
      newItems[index].extPrice = newItems[index].unitPrice * newItems[index].qty;
      newItems[index].gst = newItems[index].extPrice * 0.1;
      newItems[index].total = newItems[index].extPrice + newItems[index].gst;
    }


    setItems(newItems);
  };

  const handleSupplierDetailsChange = (field, value) => {
    setSupplierDetails({ ...supplierDetails, [field]: value });
  };

  const handleOrderPlacedByChange = (field, value) => {
    setOrderPlacedBy({ ...orderPlacedBy, [field]: value });
  };

  const handleScopeOfWorksChange = (field, value) => {
    setScopeOfWorks({ ...scopeOfWorks, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!workOrderNumber) {
      setErrorMessage('Work Order Number is required.');
      workOrderRef.current.focus();
      return;
    } else {
      setErrorMessage('');
    }

    const formData = isProgrammedWorkOrder
      ? {
          programmedWorkOrder: { workOrderNumber },
          purchaseOrderNumber,
          companyName: supplierDetails.companyName,
          companyPhone: supplierDetails.companyPhone,
          companyEmail: supplierDetails.companyEmail,
          companyABN: supplierDetails.companyABN,
          customerName,
          customerAddress,
          customerContact,
          issueDate: issueDate ? moment(issueDate).format('DD/MM/YYYY') : '',
          startDate: startDate ? moment(startDate).format('DD/MM/YYYY') : '',
          finishDate: finishDate ? moment(finishDate).format('DD/MM/YYYY') : '',
          orderPlacedByName: orderPlacedBy.name,
          orderPlacedByPosition: orderPlacedBy.position,
          orderPlacedByContactNumber: orderPlacedBy.contactNumber,
          orderPlacedByEmail: orderPlacedBy.email,
          makeSafe: scopeOfWorks.makeSafe,
          notes: scopeOfWorks.notes,
          totalCost: items.reduce((acc, item) => acc + item.total, 0).toFixed(2),
          items: items.map(item => ({
            code: item.code,
            description: item.description,
            qty: item.qty,
            unitPrice: item.unitPrice,
            extPrice: item.extPrice,
            gst: item.gst,
            total: item.total
          })),
          paymentStatus: "UNPAID"
        }
      : {
          workOrder: { workOrderNumber },
          purchaseOrderNumber,
          companyName: supplierDetails.companyName,
          companyPhone: supplierDetails.companyPhone,
          companyEmail: supplierDetails.companyEmail,
          companyABN: supplierDetails.companyABN,
          customerName,
          customerAddress,
          customerContact,
          issueDate: issueDate ? moment(issueDate).format('DD/MM/YYYY') : '',
          startDate: startDate ? moment(startDate).format('DD/MM/YYYY') : '',
          finishDate: finishDate ? moment(finishDate).format('DD/MM/YYYY') : '',
          orderPlacedByName: orderPlacedBy.name,
          orderPlacedByPosition: orderPlacedBy.position,
          orderPlacedByContactNumber: orderPlacedBy.contactNumber,
          orderPlacedByEmail: orderPlacedBy.email,
          makeSafe: scopeOfWorks.makeSafe,
          notes: scopeOfWorks.notes,
          totalCost: items.reduce((acc, item) => acc + item.total, 0).toFixed(2),
          items: items.map(item => ({
            code: item.code,
            description: item.description,
            qty: item.qty,
            unitPrice: item.unitPrice,
            extPrice: item.extPrice,
            gst: item.gst,
            total: item.total
          })),
          paymentStatus: "UNPAID"
        };

    console.log('Submitting form data:', formData);
    setStatusMessages(prevMessages => [...prevMessages, 'Submitting purchase order...']);
    setIsGeneratingPDF(true);

    const token = localStorage.getItem('token');
    try {
      const url = isProgrammedWorkOrder
        ? `${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed`
        : `${process.env.REACT_APP_API_URL}/api/purchase-orders`;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to create purchase order');
      }

      setStatusMessages(prevMessages => [...prevMessages, 'Purchase order created successfully! Generating PDF...']);

      const createdOrder = await response.json();
      const pdfResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${createdOrder.id}/pdf`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!pdfResponse.ok) {
        throw new Error('Failed to generate PDF');
      }

      const blob = await pdfResponse.blob();
      const pdfUrl = window.URL.createObjectURL(new Blob([blob]));
      setPdfUrl(pdfUrl);
      setIsGeneratingPDF(false);
      setStatusMessages(prevMessages => [...prevMessages, 'PDF generated successfully!']);
    } catch (error) {
      console.error('Error:', error);
      setStatusMessages(prevMessages => [...prevMessages, `Error: ${error.message}`]);
      setIsGeneratingPDF(false);
    }
  };

  const handleSendEmailWithAttachments = async () => {
    setIsSendingEmail(true);
    setStatusMessages(prevMessages => [...prevMessages, 'Sending email...']);

    const token = localStorage.getItem('token');

    let emailSubject;
    let emailBody;

    // General BCC address
    let bccAddress = 'programmedtradeupdates@gmail.com, technicalsupport@antilliaemergencynetwork.com.au';  // Replace with your general BCC address

    // Add additional BCC if the work order is programmed
    if (isProgrammedWorkOrder) {
        bccAddress += ',';  // Append the programmed BCC address
    }

    // CC address for programmed work orders
    let ccAddress = '';
    if (isProgrammedWorkOrder) {

        ccAddress =`${process.env.REACT_APP_PURCHASEORDER_PROGRAMMED_CC_EMAIL_USER}`;  // Replace with your specific CC address


    }

    if (isProgrammedWorkOrder) {
        emailSubject = `New Work Order - #${workOrderNumber} - ${customerAddress}`;
        emailBody = `

        To ${supplierDetails.companyName},<br><br>
        Please see the attached Work Order for details regarding the required attendance at the specified site.<br><br>
        The following information on the link below is to be provided within 24 hours of attending site:<br>
        <a href="https://trades-programmed.antilliaemergencynetwork.com.au">https://trades-programmed.antilliaemergencynetwork.com.au</a><br><br>
        <ul>
            <li>Please send relevant invoices, reports, photos, and certificates to
                <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
                and
                <a href="mailto:sgrehanmanagement@gmail.com">sgrehanmanagement@gmail.com</a>
                with reference to <strong><span style="background-color: yellow;">${purchaseOrderNumber}</span></strong>.
            </li>

            <li><span style="color: red;">Ensure all trades wear LONG & LONG onsite and attendees are PRG Inducted.</span></li>

            <li>If induction is required, please provide Working With Children's Check, Full Name, and Email to
                <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>
                and contact <a href="tel:+61405236236">0405 236 236</a>. Trades are not to attend site without an induction.
            </li>
        </ul>

        <br><br>
        A reminder to send all invoices to
        <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
        using <strong><span style="background-color: yellow;">${purchaseOrderNumber}</span></strong>.
    </p>

    <p style="font-size: 14px; font-family: helvetica;">
        Thank you,<br>
        Antillia Emergency Network.
    </p>

    <p style="font-size: 12px; font-family: helvetica; color: grey; margin-top: 20px;">
        This is an autogenerated email. Please do not reply directly to this email.
        Any replies should be sent to
        <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>.<br><br>
        This email message, including any attachments, is intended solely for the use of the individual or entity to whom it
         is addressed and may contain confidential and/or privileged information. If you are not the intended recipient or an
         authorised representative of the intended recipient, any review, dissemination, distribution, copying, or other use of
         this email or its attachments is strictly prohibited. If you have received this email in error, please notify the sender
          immediately and delete this message and all of its attachments. Thank you.
    </p>
        `;
    } else {
        emailSubject = `New Purchase Order - #${purchaseOrderNumber} - ${workOrderNumber}`;
        emailBody = `

        To ${supplierDetails.companyName},<br><br>

        Please find the attached Purchase Order.<br><br>

        Please send all invoices and related documentation to
        <a href="mailto:programmedtradeupdates@gmail.com">programmedtradeupdates@gmail.com</a>
        with reference to <strong><span style="background-color: yellow;">${purchaseOrderNumber}</span></strong>.<br><br>
    </p>

    <p style="font-size: 14px; font-family: helvetica;">
        Thank you,<br>
        Antillia Emergency Network.
    </p>

    <p style="font-size: 12px; font-family: helvetica; color: grey; margin-top: 20px;">
        This is an autogenerated email. Please do not reply directly to this email.
        Any replies should be sent to
        <a href="mailto:admin@antilliaemergencynetwork.com.au">admin@antilliaemergencynetwork.com.au</a>.<br><br>
        This email message, including any attachments, is intended solely for the use of the individual or entity to whom it
         is addressed and may contain confidential and/or privileged information. If you are not the intended recipient or an
         authorised representative of the intended recipient, any review, dissemination, distribution, copying, or other use of
         this email or its attachments is strictly prohibited. If you have received this email in error, please notify the sender
          immediately and delete this message and all of its attachments. Thank you.
    </p>
        `;
    }

    const formData = new FormData();
    formData.append('toAddress', supplierDetails.companyEmail);
    formData.append('bccAddress', bccAddress); // Pass the BCC address here
    if (ccAddress) {
      formData.append('ccAddress', ccAddress); // Pass the CC address here if it's a programmed work order
  }
    formData.append('subject', emailSubject);
    formData.append('bodyText', emailBody);
    formData.append('isProgrammedWorkOrder', isProgrammedWorkOrder);
    formData.append('workOrderNumber', workOrderNumber);

    attachments.forEach((file, index) => {
      formData.append('attachments', file); // Add each file to the form data
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-orders/${purchaseOrderNumber}/send-email`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      setStatusMessages(prevMessages => [...prevMessages, `Email sent successfully to ${supplierDetails.companyEmail}`]);
      setShowEmailSuccess(true);
      setTimeout(() => {
        setShowEmailSuccess(false);
        navigate(0); // Refresh the page after 3 seconds
      }, 3000);
    } catch (error) {
      console.error('Error:', error);
      setStatusMessages(prevMessages => [...prevMessages, `Error sending email: ${error.message}`]);
    } finally {
      setIsSendingEmail(false);
    }
};


  const handleSendEmailClick = () => {
    setShowAttachmentModal(true);
  };

  const handleAttachFiles = (files) => {
    setAttachments(files);
    setShowAttachmentModal(false);
  };

  const handleCompanySelect = (e) => {
    const selectedCompany = companies.find(company => company.name === e.target.value);
    if (selectedCompany) {
      setSupplierDetails({
        companyName: selectedCompany.name,
        companyPhone: selectedCompany.phone,
        companyEmail: selectedCompany.email,
        companyABN: selectedCompany.abn
      });
      setSelectedCompany(selectedCompany.name);
    } else {
      setSupplierDetails({
        companyName: '',
        companyPhone: '',
        companyEmail: '',
        companyABN: ''
      });
      setSelectedCompany('');
    }
  };

  const handleClearDetails = () => {
    setSupplierDetails({
      companyName: '',
      companyPhone: '',
      companyEmail: '',
      companyABN: ''
    });
    setSelectedCompany('');
  };

  const handleWorkOrderNumberChange = async (selectedOption) => {
    if (!selectedOption) return;

    const selectedNumber = selectedOption.value;
    setWorkOrderNumber(selectedNumber);

    const token = localStorage.getItem('token');
    let data;
    let purchaseOrderUrl;

    try {
      let response = await fetch(`${process.env.REACT_APP_API_URL}/admin/programmed-work-orders/details/${selectedNumber}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        data = await response.json();
        setScopeOfWorks(prevState => ({
          ...prevState,
          makeSafe: data.description || ''
        }));

        purchaseOrderUrl = `${process.env.REACT_APP_API_URL}/api/purchase-orders/programmed-work-order/${selectedNumber}`;
        setIsProgrammedWorkOrder(true);
      } else {
        response = await fetch(`${process.env.REACT_APP_API_URL}/admin/work-orders/details/${selectedNumber}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch work order details');
        }

        data = await response.json();
        setScopeOfWorks(prevState => ({
          ...prevState,
          makeSafe: data.notes || ''
        }));

        purchaseOrderUrl = `${process.env.REACT_APP_API_URL}/api/purchase-orders/work-order/${selectedNumber}`;
        setIsProgrammedWorkOrder(false);
      }

      setCustomerName(data.contactName);
      setCustomerAddress(data.address);
      setCustomerContact(data.contactPhone);

      const purchaseOrderResponse = await fetch(purchaseOrderUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!purchaseOrderResponse.ok) {
        throw new Error('Failed to fetch purchase order numbers');
      }

      const purchaseOrderData = await purchaseOrderResponse.json();
      const newPurchaseOrderNumber = generateNextPurchaseOrderNumber(selectedNumber, purchaseOrderData);
      setPurchaseOrderNumber(newPurchaseOrderNumber);

    } catch (error) {
      console.error('Error fetching work order details:', error);
    }
  };

  const generateNextPurchaseOrderNumber = (workOrderNumber, purchaseOrders) => {
    const filteredOrders = purchaseOrders.filter(order => order.purchaseOrderNumber.startsWith(workOrderNumber));
    const orderCount = filteredOrders.length;
    const nextNumber = orderCount + 1;
    return `${workOrderNumber}-${String(nextNumber).padStart(2, '0')}`;
  };

  const workOrderOptions = [
    {
      label: 'Programmed Work Orders',
      options: programmedWorkOrderNumbers.map(number => ({ value: number, label: number })),
    },
    {
      label: 'Other Work Orders',
      options: workOrderNumbers.map(number => ({ value: number, label: number })),
    },
  ];

  const calculateTotals = () => {
    const subtotal = items.reduce((acc, item) => acc + item.extPrice, 0);
    const gst = items.reduce((acc, item) => acc + item.gst, 0);
    const total = subtotal + gst;
    return { subtotal, gst, total };
  };

  const { subtotal, gst, total } = calculateTotals();

  return (
    <>
      <AdminHeader />
      <div className="form-container">
        <h2>New Purchase Order</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Work Order Number:<span style={{ color: 'red' }}> *</span></label>
            <Select
              options={workOrderOptions}
              onChange={handleWorkOrderNumberChange}
              ref={workOrderRef}
              className={`react-select-container ${errorMessage ? 'error-border' : ''}`}
              classNamePrefix="react-select"
              placeholder="Select a work order number"
              formatGroupLabel={(data) => (
                <div style={{ fontWeight: 'bold' }}>{data.label}</div>
              )}
              value={workOrderOptions.flatMap(group => group.options).find(option => option.value === workOrderNumber)}
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>
          <div className="supplier-details">
            <h3>Supplier Details</h3>
            <div className="form-group">
              <label>Select Company:</label>
              <div className="dropdown-container">
                <select value={selectedCompany} onChange={handleCompanySelect} required style={{ width: '80%' }}>
                  <option value="" disabled>Select a company</option>
                  {companies.map((company, index) => (
                    <option key={index} value={company.name}>{company.name}</option>
                  ))}
                </select>
                <button className="purchase-clear-btn" type="button" onClick={handleClearDetails} style={{ width: '20%' }}>Clear</button>
              </div>
            </div>
            <div className="form-group">
              <label>Company Name:</label>
              <input type="text" value={supplierDetails.companyName} required onChange={(e) => handleSupplierDetailsChange('companyName', e.target.value)} />
            </div>
            <div className="form-group">
              <label>Company Phone:</label>
              <input type="text" value={supplierDetails.companyPhone} required onChange={(e) => handleSupplierDetailsChange('companyPhone', e.target.value)} />
            </div>
            <div className="form-group">
              <label>Company Email:</label>
              <input type="email" value={supplierDetails.companyEmail} required onChange={(e) => handleSupplierDetailsChange('companyEmail', e.target.value)} />
            </div>
            <div className="form-group">
              <label>Company ABN:</label>
              <input type="text" value={supplierDetails.companyABN} required onChange={(e) => handleSupplierDetailsChange('companyABN', e.target.value)} />
            </div>
          </div>
          <div className="divider"></div>
          <h3>Customer Details</h3>
          <div className="form-group">
            <label>Customer Address:</label>
            <input type="text" value={customerAddress} onChange={(e) => setCustomerAddress(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Customer Name:</label>
            <input type="text" value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Customer Contact:</label>
            <input type="text" value={customerContact} onChange={(e) => setCustomerContact(e.target.value)} />
          </div>
          <div className="divider"></div>
          <div className="form-group">
            <label>Issue Date:</label>
            <DatePicker
                selected={issueDate}
                onChange={setIssueDate}
                dateFormat="dd/MM/yyyy"
            />
        </div>
        <div className="form-group">
            <label>Start Date:</label>
            <DatePicker
                selected={startDate}
                onChange={setStartDate}
                dateFormat="dd/MM/yyyy"
            />
        </div>
        <div className="form-group">
            <label>Finish Date:</label>
            <DatePicker
                selected={finishDate}
                onChange={setFinishDate}
                dateFormat="dd/MM/yyyy"
            />
        </div>
          <div className="divider"></div>
          <h3>Items</h3>
          <table className="items-table">
            <thead>
              <tr>
                <th className="code-column">Code</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Ext Price</th>
                <th>GST</th>
                <th>Total</th>
              </tr>
            </thead>
                  <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td className="code-column">{item.code}</td>
            {item.code === '1101 - Labour' ? (
              <td>
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                />
              </td>
            ) : (
              <td>
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                />
              </td>
            )}
            <td>
              <input
                type="number"
                value={item.qty}
                onChange={(e) => handleItemChange(index, 'qty', parseInt(e.target.value))}
                min="0"
              />
            </td>
            <td>
              <input
                type="number"
                value={item.unitPrice}
                onChange={(e) => handleItemChange(index, 'unitPrice', parseFloat(e.target.value))}
                min="0"
                step="0.01"
              />
            </td>
            <td>{item.extPrice.toFixed(2)}</td>
            <td>{item.gst.toFixed(2)}</td>
            <td>{item.total.toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
          </table>
          <div className="totals-section">
            <table>
              <tbody>
                <tr>
                  <td>SUBTOTAL</td>
                  <td>${subtotal.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>GST</td>
                  <td>${gst.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>TOTAL</td>
                  <td>${total.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="divider"></div>
          <h3>Order Placed By</h3>
          <div className="form-group">
            <label>Name:</label>
            <input type="text" value={orderPlacedBy.name} onChange={(e) => handleOrderPlacedByChange('name', e.target.value)} />
          </div>
          <div className="form-group">
            <label>Position:</label>
            <input type="text" value={orderPlacedBy.position} readOnly className="read-only" />
          </div>
          <div className="form-group">
            <label>Contact Number:</label>
            <input type="tel" value={orderPlacedBy.contactNumber} onChange={(e) => handleOrderPlacedByChange('contactNumber', e.target.value)} />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" value={orderPlacedBy.email} readOnly className="read-only" />
          </div>
          <div className="divider"></div>
          <h3>Scope of Works</h3>
          <div className="form-group">
            <label>Make Safe:</label>
            <textarea
              value={scopeOfWorks.makeSafe}
              onChange={(e) => handleScopeOfWorksChange('makeSafe', e.target.value)}
              rows="15"
              style={{ width: '100%' }}
            />
          </div>
          <div className="form-group full-width">
            <label>Notes:</label>
            <textarea
              value={scopeOfWorks.notes}
              onChange={(e) => handleScopeOfWorksChange('notes', e.target.value)}
              rows="20"
            />
          </div>
          <div className="divider"></div>
          <div className="form-group">
            <label>Purchase Order No:</label>
            <input
              type="text"
              value={purchaseOrderNumber}
              readOnly
            />
          </div>

          <button className="submit-invoice" type="submit">Submit</button>
        </form>
        {isGeneratingPDF && (
          <div className="loading-container">
            <ClipLoader size={50} color="#123abc" loading={true} />
            <p>Generating PDF...</p>
          </div>
        )}
        {pdfUrl && (
          <div>
            <div ref={pdfContainerRef} className="pdf-preview"></div>
            <div className="status-message">
              {statusMessages.map((message, index) => (
                <p key={index}>{message}</p>
              ))}
            </div>
            <a href={pdfUrl} download={`Purchase_Order_${purchaseOrderNumber}.pdf`} className="pdf-download-button">
              Download PDF
            </a>
            <button
              className="pdf-download-button"
              onClick={handleSendEmailClick}
              disabled={isSendingEmail}
            >
              {isSendingEmail ? 'Sending Email...' : `Send Email to ${supplierDetails.companyEmail}`}
            </button>
          </div>
        )}
      </div>
      {showEmailSuccess && (
        <div className="email-success-popup">
          <div className="email-success-content">
            <span className="email-success-text">Email successfully sent!</span>
            <span className="email-success-icon">✔️</span>
          </div>
        </div>
      )}
      <p className="centered-text">
        Having issues? Contact IT Technical Support on <span className="phone-number">0405 236 236</span>
      </p>
      <AdminFooter />

      <AttachmentModal
        isOpen={showAttachmentModal}
        onClose={() => {
          setShowAttachmentModal(false);
          handleSendEmailWithAttachments(); // Send email when "No" is selected
        }}
        onProceed={() => setShowFileUploadModal(true)}
      />

      <FileUploadModal
        isOpen={showFileUploadModal}
        onClose={() => {
          setShowFileUploadModal(false);
          setShowAttachmentModal(false);
        }}
        onAttachFiles={handleAttachFiles}
        onSendEmail={() => {
          setShowFileUploadModal(false);
          handleSendEmailWithAttachments();
        }}
        attachments={attachments}
        setAttachments={setAttachments}
      />
    </>
  );
};

// AttachmentModal Component (Step 1)
const AttachmentModal = ({ isOpen, onClose, onProceed }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Do you want to attach additional files ?</h2>
        <div className="modal-actions">
          <button  onClick={onProceed}>Yes</button>
          <button  onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

// FileUploadModal Component (Step 2)
const FileUploadModal = ({ isOpen, onClose, onAttachFiles, onSendEmail, attachments, setAttachments }) => {
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachments(prevAttachments => [...prevAttachments, ...files]);
  };

  const handleRemoveFile = (index) => {
    setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
  };

  const handleAttachFiles = () => {
    onAttachFiles(attachments);
    onSendEmail(); // Trigger email sending after attaching files
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Attach Files (8MB Max Size)</h2>
        <input type="file" multiple onChange={handleFileChange} />
        <ol className="file-list">
          {attachments.map((file, index) => (
            <li key={index}>
              {file.name} <button onClick={() => handleRemoveFile(index)}>✖</button>
            </li>
          ))}
        </ol>
        <div className="modal-actions">
          <button onClick={handleAttachFiles}>Send Email</button>
          <button
            onClick={onClose}
            style={{ color: 'red', fontWeight: 'bold' }} // Inline CSS for red "Cancel" button
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewPurchaseOrder;
